import request from "./request";

/**
 * @method makeApiRequest This method is a wrapper on all network calls. If any kind
 * of customization is required, that should be done here
 * @param {*} method The type of request, GET, POST, PUT, DELETE etc
 * @param {*} url The url needs to be called
 * @param {*} data The data needs to be passed to server
 * @param {*} params For get request, if any parameter need to be passed along with URL\
 * @param {*} headers For adding any custom header
 * @param {*} isMedia boolean: If the network call is for uploading media file
 */
/* eslint-disable */
const makeApiRequest = ({
	method,
	url,
	data = {},
	headers,
	params = undefined,
	isMedia = false,
	isAuthRequired = false
}: any) => {
	let requestHeaders = {
		...headers,
		'Content-Type': isMedia ?  'multipart/form-data' : 'application/json',
		...(isAuthRequired && { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` })
	}

	return new Promise(async (resolve, reject) => {
		request({
			method,
			url,
			data,
			params,
			headers: requestHeaders
		}).then(response => {
			return resolve(response.data);
		}).catch(err => {
			// console.log(err);
			return reject(err.response.data);
		});
	});
};

export default makeApiRequest;
