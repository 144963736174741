import React, { useEffect, useState } from "react";
import styles from "./Patient.module.scss";
import PatientWelcome from "../../components/PatientWelcome/PatientWelcome";
import PatientAnswers from "../../components/PatientAnswers/PatientAnswers";
import PatientSuccess from "../../components/PatientSuccess/PatientSuccess";
import { useLocation } from "react-router-dom";
import makeApiRequest from "../../services/network";
import { CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";

const Patient: React.FC = () => {
  const history = useHistory();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [flowData, setFlowData] = useState<any>({} as any);
  const [logoUrl, setLogoUrl] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(true);

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const orgId = query.get("org_id");
  const programId = query.get("pg_id");
  const programName = query.get("prg_name");
  const flowId = query.get("fl_id");
  const patientId = query.get("pt_id");
  const responseId = query.get("resp_id");

  useEffect(() => {
    setLoader(true);
    Promise.all([getFlowDetails(), getLogo()]).then(result => {
      setFlowData(result[0]);
      setLogoUrl(result[1].data.logoUrl);
      setLoader(false);
    }).catch((e) => {
      setLoader(false);
      history.push({
        pathname: "/error",
        state: {code: e.error.errorCode, message: e.error.errorMessage}
      });
    });
  }, []);

  /**
   * This Method fetches Flow details on basis of flow id.
   */
  const getFlowDetails = async () => {
    try {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const result = await makeApiRequest({
        method: "GET",
        url: `${baseUrl}/flows/${flowId}/programs/${programId}/responses/${responseId}`
      }) as any;
      return result["data"];
    } catch (e) {
      throw e;
    }
  };

  /**
   * This Method fetches logo on basis of org id.
   */
  const getLogo = async () => {
    try {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const data = await makeApiRequest({
        method: "GET",
        url: `${baseUrl}/logo?organization=${orgId}`
      }) as any;
      return data;
    } catch (e) {
      throw e;
    }
  };

  const incrementActiveStep = (step: number) => {
    setActiveStep(step);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <PatientWelcome
            logoUrl={logoUrl}
            goToStep={(step: number) => incrementActiveStep(step)}
          />
        );
      case 1:
        return (
          <PatientAnswers
            flowData={flowData}
            patientId={patientId}
            programId={programId}
            programName={programName}
            responseId={responseId}
            goToStep={(step: number) => incrementActiveStep(step)}
          />
        );
      case 2:
        return <PatientSuccess />;
      default:
        throw new Error("Unknown step");
    }
  };

  return (
    <div
      className={`${activeStep === 1 ? styles.PatientAnswers : ""} ${
        styles.Patient
      }`}
    >
      {loader ? <CircularProgress /> : getStepContent(activeStep)}
    </div>
  );
};

export default Patient;
