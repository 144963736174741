import styled, { keyframes } from "styled-components";

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  padding: 0 0 0 35px;
  font-size: 18px;
  font-weight: 600;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`;

const rotate = keyframes`
 from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

const Indicator = styled.div`
  width: 1.2em;
  height: 1.2em;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #383838;
  border-radius: 0.2em;

  ${Input}:not(:disabled):checked & {
    background: #d1d1d1;
  }

  ${Label}:hover & {
    background: #fff;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + &::after {
    display: block;
    top: 0.1em;
    left: 8px;
    width: 18%;
    height: 60%;
    border: solid #4F9759;
    border-width: 0 0.2em 0.2em 0;
    animation-name: ${rotate};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  &::disabled {
    cursor: not-allowed;
  }
`;

interface CheckBoxProps {
  id: string;
  label: string;
  disabled?: boolean;
  name?: string;
  value: string;
  checked: boolean;
  onChange: (event: any) => void;
}


const CheckBox = ({value, onChange, name, id, label, disabled, checked}: CheckBoxProps) =>{
  return (
    <Label htmlFor={id} disabled={disabled}>
      {label}
      <Input
        id={id}
        type="checkbox"
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        checked={checked}
      />
      <Indicator />
    </Label>
  );
}

export default CheckBox