import { useState, useEffect } from "react";
import { IAnswerOption } from "../../hooks/useStoreselectedAnswer";

import CheckBox from "../CheckBox/CheckBox";
import RadioButton from "../RadioButton/RadioButton";
import styles from "./Answer.module.scss";

interface AnswerProps {
  question: any;
  answers: IAnswerOption[];
  onSelect: (selectedValue: string) => void;
}

const Answer = ({ question, answers = [], onSelect }: AnswerProps) => {
  const [selectedAnswers, setSelectedAnswers] = useState<any>([]);  
  const prevSelectedAnswers = answers.map(answer => {
    return answer.valueString;
  });

  useEffect(() => {
    onSelect(selectedAnswers);
  }, [selectedAnswers])

  const onCheckboxSelected = (event) => {
    const { value, checked } = event.target;
    if (checked && !(prevSelectedAnswers.includes(value))) {
      setSelectedAnswers([...prevSelectedAnswers, value]);
    } else if (!checked && (prevSelectedAnswers.includes(value))) {
      const index = prevSelectedAnswers.indexOf(value);
      if (index > -1) {
        const unSelectedAnswer = [...prevSelectedAnswers];
        unSelectedAnswer.splice(index, 1);
        setSelectedAnswers(unSelectedAnswer);
      }
    }
  }

  const onRadioButtonSelected = (event) => {
    const { value } = event.target;
    setSelectedAnswers([value]);
  }

  const isCheckboxChecked = (value) => {
    return answers.filter(answer => answer.valueString === value).length > 0;
  }

  const isRadioButtonChecked = (value) => {
    return answers.filter(answer => answer.valueString === value).length > 0;
  }

  const renderOption = (type: string) => {
    switch (type) {
      case "choice":
        return <ul className={styles.OptionList}>
          {question.answerOption.map((option, index) => {
            return (
              <li key={index}>
                {question.extension.value === "check-box" ? (
                  <CheckBox
                    id={`checkbox-${index}`}
                    name={`checkbox-${question.linkId}`}
                    label={option.valueString.display}
                    value={option.valueString.display}
                    onChange={onCheckboxSelected}
                    checked={isCheckboxChecked(option.valueString.display)}
                  />
                ) : (
                  <RadioButton
                    id={`radio-${index}`}
                    label={option.valueString.display}
                    name={`radio-${question.linkId}`}
                    value={option.valueString.display}
                    onChange={onRadioButtonSelected}
                    checked={isRadioButtonChecked(option.valueString.display)}
                  />
                )}
              </li>
            );
          })}
        </ul>
      default:
        return "";

    }
  }

  return (
    <div className={styles.Answer}>
      <p>{question?.text}</p>
      {renderOption(question?.type)}
    </div>
  );
};

export default Answer;
