import React from "react";
import styles from "./PatientWelcome.module.scss";

interface PatientWelcomeProps {
  logoUrl: string;
  goToStep: (step: number) => void;
}
const PatientLogin: React.FC<PatientWelcomeProps> = ({
  goToStep, logoUrl
}) => {

  return (
    <div className={styles.PatientLogin}>
      <img src={logoUrl} width="220" alt="healthipeople logo" />
      <button
        type="button"
        className={styles.submitBtn}
        onClick={() => goToStep(1)}
      >
        GET STARTED
      </button>

      <div className={styles.footer}>Powered by Videoreach</div>
    </div>
  );
};

export default PatientLogin;
