import { Container, Box, Grid, Typography, Link as MaterialLink } from "@mui/material"
import { Link } from "react-router-dom";

const PageNotFound = () => {
    return (
        <Container component="main" maxWidth="lg">
        <Box 
            sx={{
                marginTop: '30vh',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
          <Typography component="h1" variant="h1" fontSize='10rem'>
            <span style={{color: '#383838'}}>4</span>
            <span style={{color: '#F25832'}}>0</span>
            <span style={{color: '#383838'}}>4</span>
          </Typography>
          <Typography component="h1">
            THE PAGE YOU REQUESTED COULD NOT BE FOUND
          </Typography>
          <Grid container justifyContent="center">
              <Grid item>
                <MaterialLink to="/" variant="body2" component={Link}>
                    <Typography textAlign='center'>
                            Click here to go back to the Homepage
                        </Typography>
                </MaterialLink>
              </Grid>
          </Grid>
        </Box>
      </Container>
    )
}

export default PageNotFound;