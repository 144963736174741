import styles from "./Error.module.scss";
import error from "../../assets/ic_error.svg";
import check from "../../assets/ic_done.svg";

import { useHistory, useLocation } from "react-router-dom";

interface CustomizedState {
    code: string;
    message: string;
}

const ErrorPage = () => {
  const history = useHistory();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const {code, message} = state;
  const isDisabled = false;
  const errorIcon = code.toString().toLowerCase() === 'ec101' ? check : error;
  const errorText = code.toString().toLowerCase() === 'ec101' ? "Thank You! You've already responded" : message;

  return (
    <div className={styles.ErrorWrapper}>
      <div className={styles.box}>
        <div className={styles.CheckLogo}>
          <img src={errorIcon} className={styles.ErrorIcon} alt="error logo" />
        </div>
        <h3 className={styles.ErrorMessage}>{errorText}.</h3>
        {code.toString().toLowerCase() !== 'ec101' ? (
        <button
          type="button"
          disabled={isDisabled}
          className={styles.ButtonSubmit}
          onClick={() => history.push("/")}
        >
          Click here to Re-submit
        </button>) : null
        }
      </div>
    </div>
  );
};

export default ErrorPage;
