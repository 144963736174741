import { Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary/ErrorBoundary";
import Patient from "../pages/Patient/Patient";
import ErrorPage from "../pages/Error/Error";
import PageNotFound from "../pages/PageNotFound/PageNotFound";

const Routes = () => {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Router>
          <ErrorBoundary>
            <Switch>
              <Route exact path="/">
                <Redirect to="/survey" />
              </Route>
              <Route exact path="/survey" component={Patient} />
              <Route exact path="/error" component={ErrorPage} />
              <Route>
                <PageNotFound />
              </Route>
            </Switch>
          </ErrorBoundary>
        </Router>
      </Suspense>
    </>
  );
};
export default Routes;
