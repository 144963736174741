import React from "react";
import styles from "./PatientSuccess.module.scss";
import check from "../../assets/ic_done.svg";

const PatientSuccess = () => {
  const completedMessage =
    "Thank you for answering all the questions! We have successfully recorded your responses.";
  return (
    <div className={styles.PatientSuccess}>
      <div className={`${styles.box}`}>
        <img src={check} alt="success logo" />
        <h3>{completedMessage}</h3>
      </div>
    </div>
  );
};

export default PatientSuccess;
