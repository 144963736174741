import React, { useEffect, useState } from "react";
import styles from "./PatientAnswers.module.scss";
import { CircularProgress } from "@mui/material";
import MediaPlayer from "../MediaPlayer/MediaPlayer";
import Answer from "../Answer/Answer";
import useStoreSelectedAnswers from "../../hooks/useStoreselectedAnswer";
import makeApiRequest from "../../services/network";
import { useHistory } from "react-router-dom";

interface IPatientAnswersProps {
  flowData: any;
  patientId: string;
  programName: string;
  programId: string;
  responseId: string;
  goToStep: (step: number) => void;
}

const PatientAnswers: React.FC<any> = ({
  flowData,
  patientId,
  goToStep,
  programName,
  programId,
  responseId,
}: IPatientAnswersProps) => {
  const history = useHistory();

  const questions = flowData.item;

  const { getSelectedAnswer, getSelectedAnswers, setSelectedAnswers } =
    useStoreSelectedAnswers();

  let isNextButtonEnable = false;

  const [counter, setCounter] = useState<number>(0);
  const [activeQuestion, setActiveQuestion] = useState<any>(questions[counter]);
  const [itemAnswers, setItemAnswers] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [videoPlayedDuration, setVideoPlayedDuration] = useState<number>(0);
  const [totalVideoDuration, setTotalVideoDuration] = useState<number>(0);

  useEffect(() => {
    setActiveQuestion(questions[counter]);
    if (counter === questions.length) {
      submitResponse();
    }
  }, [questions, counter]);

  const onAnwserSelected = (selectedValue: any) => {
    let stepAnswer = selectedValue.map((value) => {
      return {
        valueString: value,
      };
    });
    const prevAnswer = getSelectedAnswers();
    const answer = activeQuestion && {
      ...prevAnswer,
      [activeQuestion.linkId]: stepAnswer,
    };
    setSelectedAnswers(answer);
  };

  const handleDuration = (duration) => {
    setTotalVideoDuration(duration);
  };

  const handleSeek = (progress) => {
    setVideoPlayedDuration(progress);
  };

  const handleProgress = (progress) => {
    setVideoPlayedDuration(progress.playedSeconds);
  };

  const onPreviousClick = () => {
    setCounter(counter - 1);
    setItemAnswers(itemAnswers.filter((resonse) => resonse.stepNo !== counter));
  };

  const onNextClick = () => {
    const itemAnswer = {
      stepNo: counter + 1,
      stepName: activeQuestion.stepName,
      linkId: activeQuestion.linkId,
      text: activeQuestion.text,
      video: {
        videoId: activeQuestion.video.videoId,
        watchedDuration: videoPlayedDuration,
        totalDuration: totalVideoDuration,
      },
      answer: getSelectedAnswer(activeQuestion.linkId),
    };
    setItemAnswers([...itemAnswers, itemAnswer]);
    setCounter(counter + 1);
    if (activeQuestion?.type.toLowerCase() !== "info") {
      isNextButtonEnable = false;
    }
  };

  if (activeQuestion?.type.toLowerCase() !== "info") {
    isNextButtonEnable = getSelectedAnswer(activeQuestion?.linkId)?.length > 0;
  } else {
    isNextButtonEnable = true;
  }

  const buttonText = questions.length - 1 === counter ? "Submit" : "Next";

  /**
   * This method invokes the interaction response API to submit the selected/recorded answer.
   */
  const submitResponse = async () => {
    setLoader(true);

    const request = {
      resourceType: "QuestionnaireResponse",
      questionnarie: flowData.flowId,
      author: patientId,
      status: "completed",
      programId: programId,
      programName: programName,
      orgId: flowData.orgId,
      responseId: responseId,
      item: itemAnswers,
    };

    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    await makeApiRequest({
      method: "POST",
      url: `${baseUrl}/response`,
      data: request,
    })
      .then(() => {
        setLoader(false);
        goToStep(2);
      })
      .catch((e) => {
        setLoader(false);
        history.push("/error");
      });
  };

  const renderBottomToolbar = () => {
    return (
      <div className={styles.ButtonWrapper}>
        <button
          type="button"
          className={styles.ButtonSubmit}
          onClick={onPreviousClick}
          disabled={counter === 0}
        >
          Previous
        </button>
        <button
          type="button"
          className={styles.ButtonSubmit}
          onClick={onNextClick}
          disabled={!isNextButtonEnable}
        >
          {buttonText}
        </button>
      </div>
    );
  };

  return (
    <div className={styles.PatientAnswers}>
      {loader ? (
        <CircularProgress className={styles.CircularLoader} />
      ) : (
        <>
          <MediaPlayer
            videoUrl={activeQuestion?.video?.cloudfrontUrl}
            handleDuration={handleDuration}
            handleProgress={handleProgress}
            handleSeek={handleSeek}
          />
          <Answer
            question={activeQuestion}
            answers={getSelectedAnswer(activeQuestion?.linkId)}
            onSelect={onAnwserSelected}
          />
          {renderBottomToolbar()}
        </>
      )}
    </div>
  );
};

export default PatientAnswers;
