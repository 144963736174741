import React from "react";
import ReactPlayer from "react-player";
import styles from "./MediaPlayer.module.scss";

interface MediaPlayerProps {
  videoUrl: string;
  handleDuration: (duration: any) => void;
  handleProgress: (progress: any) => void;
  handleSeek: (played: any) => void;
}

const MediaPlayer: React.FC<any> = ({
  videoUrl,
  handleDuration,
  handleProgress,
  handleSeek,
}: MediaPlayerProps) => {

  return (
    <div className={styles.PlayerWrapper}>
      <ReactPlayer
        className={styles.ReactPlayer}
        key={videoUrl}
        url={videoUrl}
        width="100%"
        height="100%"
        controls={true}
        pip={false}
        onDuration={handleDuration}
        onProgress={handleProgress}
        onSeek={handleSeek}
        config={{
          file: { attributes: { playsInline: true } },  
        }}
      />
    </div>
  );
};

export default MediaPlayer;
