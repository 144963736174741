import { useState } from "react";


export interface IAnswerOption {
    valueString: string;
}

export interface ISelectedAnswer {
    [key: string]: IAnswerOption[]
}


export default function useStoreSelectedAnswers() {
    const [selectedAnswers, setSelectedAnswers] = useState<ISelectedAnswer | []>([]);

    const getSelectedAnswers = () => {
        return selectedAnswers;
    }

    const getSelectedAnswer = (linkid) => {
        // return answer for a question
        if (!linkid) return;

        return selectedAnswers[linkid];
    }

    return { getSelectedAnswer, getSelectedAnswers, setSelectedAnswers };
}
